<template>
  <div class="container-fluid">
    <Header
      header="Home"
      title="Drafting Ditolak"
      :state="state"
      :addLink="goToAddView"
      :forbidden="forbid"
    />

    <router-view/>
  </div>
</template>

<script>
import Header from "@/components/pageTitle/index";

export default {
  components: {
    Header
  },
  computed: {
    state() {
      return this.$store.state.drafting;
    },
    forbid() {
      return true
    }
  },
  methods: {
    goToAddView() {
      this.$store.dispatch("drafting/onAdd");
    }
  }
};
</script>
<style>
.mt-20 {
  margin-top: 14rem !important;
  margin-bottom: 13rem !important;
}
@media only screen and (max-width: 600px) {
  .mt-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
}
</style>
